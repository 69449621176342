.Header {
  background-color: #f6f6f6 !important;
  border-bottom: #cccccc solid thin;
}

.Header__Logo {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.Header__ShopSelect {
  width: 300px;
}

.Header__Extra {
  display: flex;
  align-items: center;
  height: 100%;
}
