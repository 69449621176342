@import "./colors";
@import "./fonts";

$primary-color: $pepsi-blue;
$secondary-color: $light-orange;
$action-color: $light-green;
$disabled-color: $gray;
$error-color: $red;
$text-color: $dark-gray;
$title-color: $light-orange;
$description-color: $light-gray;
$promo-color: $lightest-blue;

$header-promo-height: 28px;
$header-height: 60px;
$header-height-mobile: 40px;

$margin-base: 10px;
$padding-base: 10px;

$base-font-sans-serif: "dm-sans-regular", "Avenir LT W01_45 Book1475508", "Helvetica Neue",
  "Helvetica", sans-serif;
$dm-sans-bold: "dm-sans-bold", "dm-sans-medium", "dm-sans-regular", "Helvetica Neue", "Helvetica",
  sans-serif;
$dm-sans-medium: "dm-sans-medium", "dm-sans-regular", "Helvetica Neue", "Helvetica", sans-serif;
$dm-sans-regular: "dm-sans-regular", "Helvetica Neue", "Helvetica", sans-serif;
$value-serif-bold: "value-serif-bold-pro", "Times New Roman", "Times", "Baskerville", "Georgia",
  serif;

$z-index-modal: 10001;
$z-index-header: $z-index-modal - 1;

$pepdirect-blue: #023e73;
$gatorade-gray: #f2f2f2;

// BREAKPOINT WIDTHS
// simplified version of https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
$mobile-min: 320px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$laptop-min: 1024px;

// BREAKPOINTS
@mixin mobile {
  @media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-laptop {
  @media (max-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$mobile-max}) {
    @content;
  }
}
