@import "variables";

.spinner-container.white .dot {
  background: white;
}

.spinner-container {
  position: relative;
  width: 100%;
}

.spinner {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 50px;
  transform: translate(-50%, -50%);
}

.spinner .dot {
  width: 10px;
  height: 10px;
  background: $primary-color;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: dots 1.4s infinite ease-in-out both;
  animation: dots 1.4s infinite ease-in-out both;
}

.spinner .dot1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .dot2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes dots {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0) translateZ(0);
    transform: scale(0) translateZ(0);
  }
  40% {
    -webkit-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
  }
}
