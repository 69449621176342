@import "variables";

input#variant,
input#quantity {
  // on IE 11, an input with readonly attribute still has a cursor visible
  // setting width here on the inputs so that it's not visible B2B-551
  width: 0px;
}

/* Ant design overrides */
.ant-pagination-total-text {
  float: right;
}

.ant-page-header-heading-title {
  font-size: 24px !important;
}

.ant-page-header-heading-sub-title {
  align-self: flex-end;
  color: $pepdirect-gray !important;
  font-size: 12px;
  font-weight: 500;
}

.ant-message {
  z-index: $z-index-header + 1 !important;
}

.table {
  font-family: "Roboto", sans-serif;
}
.table .ant-table-small .ant-table-thead > tr > th {
  background-color: #fff;
  font-weight: 700;
  border-bottom-width: 2px;
  border-bottom-color: #bdbdbd;
}
.table .ant-table-small tbody > tr > td:nth-child(1) {
  color: $pepdirect-blue;
}
.table .ant-table-small tbody > tr {
  font-size: 16px;
}

.table .ant-table tfoot > tr > td {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: #bdbdbd;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.ant-upload-draggable-list-item {
  border: 2px dashed rgba(0, 0, 0, 0);
}
.ant-upload-draggable-list-item.drop-over-downward {
  border-color: #093965;
}

.ant-upload-draggable-list-item.drop-over-upward {
  border-color: #1890ff;
}

.ant-modal-content .ant-modal-footer {
  padding: 24px;
}
