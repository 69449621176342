@font-face {
  font-family: "value-serif-bold-pro";
  src: url("https://assets.decovostatic.com/fonts/value-serif-bold-pro.eot");
  src: url("https://assets.decovostatic.com/fonts/value-serif-bold-pro.eot?#iefix")
      format("embedded-opentype"),
    url("https://assets.decovostatic.com/fonts/value-serif-bold-pro.woff2") format("woff2"),
    url("https://assets.decovostatic.com/fonts/value-serif-bold-pro.woff") format("woff"),
    url("https://assets.decovostatic.com/fonts/value-serif-bold-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans-bold";
  src: url("https://assets.decovostatic.com/fonts/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans-regular";
  src: url("https://assets.decovostatic.com/fonts/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans-medium";
  src: url("https://assets.decovostatic.com/fonts/DMSans-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
