$pepsi-blue: #003069;
$lightest-blue: #d1eeff;
$dark-gray: #222222;
$gray: #999999;
$gray-4: #4f4f4f;
$gray-2: #d9d9d9;
$gray-3: #333333;
$gray-5: #828282;
$light-gray: #f6f6f6;
$light-green: #00883f;
$light-orange: #e07647;
$light-blue: rgba(5, 151, 242, 0.2);
$red: #a62635;
$gatorade-gray: #f2f2f2;
$pepdirect-blue: #023e73;
$pepdirect-red: #ff4d4f;
$pepdirect-gray: #bdbdbd;
